$(document).ready(function(){

    $(".lazy").lazyload({
      effect : "fadeIn"
    });

	$(window).on("scroll", function(){
		if ($(window).scrollTop() > 0) {
			$(".top-section .navbar-default").addClass("sticky-header");
		} else {
			$(".top-section .navbar-default").removeClass("sticky-header");
		}

	});
});

$.material.init();

if ($("#contact-map").length > 0) {
    //google.maps.event.addDomListener(window, 'load', init);
}

function init() {
    var mapOptions = {
        zoom: 17,
        center: new google.maps.LatLng(22.2794, 70.7752), // New York
        styles:
        [
            {
                "featureType":"water",
                "elementType":"geometry",
                "stylers":[{"color":"#c8c8c8"},{"lightness":17}]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            }
        ]
    };
    var mapElement = document.getElementById('contact-map');
    var map = new google.maps.Map(mapElement, mapOptions);
    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(22.2794, 70.7752),
        map: map,
        title: 'Snazzy!'
    });
}

$(function() {
	$('a[href*=#]:not([href=#])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
                if ($(window).width() > 640) {
    			    $('html,body').animate({
                        scrollTop: target.offset().top - 90
    			    }, 1000);
                } else {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 70
                    }, 1000);
                }
                if ($(this).parents(".navbar-collapse").hasClass("in")) {
                    $(this).parents(".navbar-collapse").removeClass("in");
                }
			    return false;
			}
		}
  	});
});

$(function(){
    var form = $(".mail-form form");
    $(form).submit(function(event){
        event.preventDefault();
        var formMessages = $(this).parents('.mail-form').find('.form-messages');
        var spinner = $(this).parents('.mail-form').find(".spinner");
        var formData = $(this).serialize();
        $(spinner).fadeIn('fast');

        $.ajax({
            type: 'POST',
            url: $(form).attr('action'),
            data: formData
        }).done(function(response) {
            $(spinner).fadeOut('fast');
            // Make sure that the formMessages div has the 'success' class.
            $(formMessages).removeClass('error');
            $(formMessages).addClass('success');

            // Set the message text.
            $(formMessages).text(response);

            // Clear the form.
            if($.trim(response) == "Thank You! We will contact you soon.") {
                $("input[type=text]").val('');
                $("input[type=email]").val('');
                $("textarea").val('');
            }
        }).fail(function(data) {
            $(spinner).fadeOut('fast');
            // Make sure that the formMessages div has the 'error' class.
            $(formMessages).removeClass('success');
            $(formMessages).addClass('error');

            // Set the message text.
            if (data.responseText !== '') {
                $(formMessages).text(data.responseText);
            } else {
                $(formMessages).text('Oops! An error occured and your message could not be sent.');
            }
        });
    });
});
